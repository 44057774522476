<template>
  <div class="wrapper">
    <main class="main">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view />
      </transition>
    </main>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import { registerWarRoomModuleContext, WAR_ROOM_MODULE_NAMESPACE } from '@/modules/warRoom'

export default defineComponent({
  name: 'WarRoomIndex',
  setup () {
    registerWarRoomModuleContext()
  },
  mounted () {
    this.setCurrentAskingModuleBelongsTo(WAR_ROOM_MODULE_NAMESPACE)
  },
  methods: {
    ...mapActions('dataSource', ['setCurrentAskingModuleBelongsTo'])
  }
})
</script>

<style lang="scss" scoped>
.main {
  padding: 0;
  height: calc(100vh - #{$header-height});
}
</style>
